<template>

</template>

<script>
import { createAuthTokenManager } from "@/utils/di";

export default {
  name: "Logout",
  mounted() {
    createAuthTokenManager().remove();
    sessionStorage.removeItem("redirectTo");
    return this.$router.replace({ name: "login" });
  },
};
</script>
